import { Button, Col, Container, Image, Modal, Stack } from "react-bootstrap";
import SuccessEmojiImage from "../assets/images/success-emoji.png";
import SmileEmojiImage from "../assets/images/smile-emoji.png";
import CarrefourImagem from "../assets/images/carrefour.png";
import { Suspense, useContext, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Roulette from "../components/Roulette";
import ConfettiExplosion from "react-confetti-explosion";
import useApi from "../hooks/useApi";
import { AppContext } from "../providers/AppProvider";
import Swal from "sweetalert2";
import { CloudImage } from "../components/Images";

export function Component() {
  const navigate = useNavigate();
  const location = useLocation();

  const app = useContext(AppContext);
  const api = useApi();

  const [copied, setCopy] = useState(false);
  const [animationEnd, setAnimationEnd] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(true);
  const [result, setResult] = useState("");
  const [premio, setPremio] = useState<any>({ categoria: "Todos os azeites" });

  const params = useParams();

  const handleSuccess = (response: any) => {
    if (response.success) {
      setResult("win");
      setPremio(response.data);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: response.message,
        confirmButtonText: "Ver meu extrato",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
    }
  };

  const handleError = (error: any) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      html: error.message,
      confirmButtonText: "Ver meus números",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then(() => {
      navigate("/meus-numeros");
    });
  };

  const handleRotate = () => {
    if (params.id) {
      api.spinRoutette(params.id).then(handleSuccess).catch(handleError);
    }
  };

  const handleRotateAgain = () => {
    setResult("");
    setPremio({});
    setShowModal(false);
    navigate(`/roleta/${premio.proximo_id}`);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(premio.voucher);
    setCopy(true);
    setTimeout(() => setCopy(false), 5000);
  };

  const handleImageError = (e: any) => {
    if (e.target.src !== CarrefourImagem) {
      e.target.src = CarrefourImagem;
    }
  };

  if (!app.isAuthenticated) {
    return <Navigate to="/login" state={{ location }} replace />;
  }

  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section className="hero w-100">
        <div
          className="hero__clouds"
          style={{ backgroundImage: CloudImage }}
        ></div>
        <div className="hero__left-light"></div>
        <div className="hero__right-light"></div>

        <Container className="roleta-container">
          <div className="d-flex align-items-center justify-content-center">
            {animationEnd && (
              <ConfettiExplosion
                force={0.8}
                duration={3000}
                particleCount={150}
                width={1600}
              />
            )}
            <Roulette
              result={result}
              onAnimationEnd={() => {
                setAnimationEnd(true);
                setTimeout(() => setShowModal(true), 500);
              }}
            />
            {animationEnd && (
              <ConfettiExplosion
                force={0.8}
                duration={3000}
                particleCount={150}
                width={1600}
              />
            )}
          </div>
          <div className="d-flex">
            <Button
              variant="danger"
              size="lg"
              className="mx-auto my-5"
              onClick={handleRotate}
              disabled={result !== ""}
            >
              <i className="bi bi-arrow-clockwise me-2"></i>
              <span>Girar a roleta</span>
            </Button>
          </div>
        </Container>
      </section>
      <Modal show={showModal}>
        <Modal.Body>
          <Stack className="align-items-center">
            <div>
              <ConfettiExplosion
                force={0.8}
                duration={4000}
                particleCount={150}
                width={1600}
                zIndex={9999}
              />
              <Image
                className="mb-4"
                src={SuccessEmojiImage}
                alt=""
                width={100}
              />
            </div>

            <h2 className="mb-4">Parabéns!</h2>

            <p>Você ganhou um oferta especial.</p>

            <div className="d-flex align-items-center gap-3 mb-1">
              <Col
                xs={{ span: 4 }}
                className="d-flex align-items-center justify-content-end"
              >
                <Image
                  onError={handleImageError}
                  src={`/assets/images/discounts/${premio.slug}.jpg`}
                  alt=""
                  width={100}
                />
              </Col>
              <Col xs={8}>
                {premio.categoria && <h3 className="fs-5">{premio.categoria}</h3>}
                {premio.dinamica && <h4 className="fs-6">{premio.dinamica}</h4>}
              </Col>
            </div>

            <p className="fs-6 text-center">
              {premio.limite && <>Limitado a {premio.limite}</>}
              <br />
              Válido somente para o mês de Abril de 2024
              <br />
              {premio.tipo && (
                <>
                  Apenas nas lojas{" "}
                  {premio.tipo === "Físico" ? "físicas" : "online"}
                </>
              )}
              <br />
            </p>

            <i className="text-center text-small mb-3">
              UTILIZE O SEU CUPOM NA LOJA{" "}
              {premio.tipo === "Físico" ? "FÍSICA APÓS 72H" : "ONLINE"}
              <br />
              CONSULTE SEUS DESCONTOS NA PÁGINA MEU EXTRATO
              <br />
              CONSULTE CONDIÇÕES E DISPONIBILIDADE NO SITE E/OU NA LOJA
              <br />
              {premio.tipo === "Físico" ? "DESCONTO VINCULADO AO SEU CPF" : ""}
            </i>

            {premio.voucher && (
              <div className="mb-4">
                <div className="text-center">Código:</div>
                <div>
                  <span>{premio.voucher}</span>
                  <Button
                    size="sm"
                    variant="primary"
                    className="ms-2"
                    onClick={handleCopy}
                  >
                    <i className="bi bi-clipboard me-1"></i>
                    <span>{copied ? "Copiado!" : "Copiar"}</span>
                  </Button>
                </div>
              </div>
            )}

            {premio.proximo_id && (
              <p className="fw-bold text-danger text-center p-2">
                Você pode girar a roleta outra vez e ganhar mais prêmios!
                <br />
                Utilize o botão abaixo ou siga para o seu extrato.
              </p>
            )}

            <Stack
              direction="horizontal"
              className="justify-content-center gap-5"
            >
              {premio.proximo_id && (
                <Button
                  variant="danger button"
                  size="sm"
                  onClick={handleRotateAgain}
                >
                  <i className="bi bi-arrow-clockwise me-2"></i>
                  <span>Girar outra vez</span>
                </Button>
              )}

              <Button
                className="button"
                onClick={() => navigate("/meu-extrato")}
              >
                Ver extrato
              </Button>
            </Stack>
          </Stack>
        </Modal.Body>
      </Modal>
      <Modal show={showRegisterModal}>
        <Modal.Body>
          <Stack className="align-items-center">
            <Image className="mb-4" src={SmileEmojiImage} alt="" width={100} />

            <h2 className="mb-4">Obaaaaaa!</h2>

            <p className="text-center">
              Parabéns!
              <br />
              Seus números da sorte foram gerados com sucesso!*
              <br />
              Agora, gire a roleta e ganhe descontos.
            </p>

            <small className="text-center mb-4">
              *Sujeito a conferência de informações e cupom fiscal conforme
              regulamento.
            </small>

            <Button
              className="button"
              onClick={() => setShowRegisterModal(false)}
            >
              OK
            </Button>
          </Stack>
        </Modal.Body>
      </Modal>
    </Suspense>
  );
}
